<template>
  <div class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-4 align-center justify-space-between">
      <span class="font-weight-bold">
        Biolink
      </span>
      <span class="caption-small s">
        Home > Biolink
      </span>
    </div>
    <v-row no-gutters class="flex-column-reverse flex-sm-row">
      <v-col cols="12" class="col-md-6 pl-sm-4 pr-sm-0">
        <v-row no-gutters class="mb-4">
          <v-col cols="12" class="d-flex align-center">
            <v-text-field
              v-model="search"
              label="Search Link"
              outlined
              append-icon="mdi-magnify"
              block
              dense
              class="rounded-pill"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              autocomplete="off"
              hide-details
            />
            <v-btn depressed color="primary" dark class="rounded-pill caption-small font-weight-bold text-capitalize ml-2" @click.prevent="OpenHomeSetting()">
              <v-icon class="mr-1" small>
                mdi-home-edit
              </v-icon>
              Home Setting
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100]
          }"
          :loading="tableLoading"
          class="accent"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="caption-small py-1 font-weight-bold d-flex align-center">
              #{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.slug`]="{ item }">
            <div class="caption-small py-1 font-weight-bold d-flex align-center">
              <v-icon small color="grey darken-2" class="mr-2" @click.prevent="copyText('https://ebright.skin/' + item.slug)">
                mdi-content-copy
              </v-icon>
              /{{ item.slug }}
            </div>
            <div class="caption-small">
              {{ item.visit_counter }} visits
            </div>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <div class="caption-small py-1">
              {{ item.title }}
            </div>
          </template>
          <template v-slot:[`item.headline`]="{ item }">
            <div class="caption-small py-1">
              {{ item.headline }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="caption-small py-1 d-flex fill-width justify-center">
              <v-switch
                v-model="table[table.findIndex(r => parseInt(r.id) === parseInt(item.id))].status"
                @change="UpdateStatus(item.id, item.slug, item.status)"
              />
            </div>
          </template>
          <template v-slot:[`item.stock_move`]="{ item }">
            <div class="caption-small py-1">
              <div class="font-weight-bold">
                {{ item.title }}
              </div>
              <div>
                {{ item.subtitle }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-center caption-small py-2">
              <v-btn small icon class="mr-1" color="red" @click.prevent="BIOLINK_DELETE(item.id)">
                <v-icon small color="red">
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn x-small fab outlined class="mr-2" color="orange" @click.prevent="OpenLinkDetail(item)">
                <v-icon small color="orange">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              <v-btn x-small fab outlined color="blue" @click.prevent="OpenLinkDetail(item, true)">
                <v-icon small color="blue">
                  mdi-content-duplicate
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="dialogBiolink.data" cols="12" class="col-md-6 px-sm-4 py-4 py-sm-0 editable-biolink">
        <div class="d-flex justify-space-between align-center fill-width px-0 px-sm-10">
          <div class="d-flex align-center caption" style="letter-spacing:0px!important;line-height:100%;">
            <v-icon small color="grey darken-2" class="mr-1" @click.prevent="copyText()">
              mdi-content-copy
            </v-icon>
            <small>https://ebright.skin/</small>
            <input
              v-model="dialogBiolink.data.slug"
              :class="[$vuetify.theme.dark ? 'orange--text text--lighten-2' : 'orange--text text--darken-2']"
              type="text"
              class="font-weight-bold body-2 pl-1"
              style="min-width:20px;"
              @keyup="dialogBiolink.data.slug = dialogBiolink.data.slug.split(' ').join('').replace(/[\W_]+/gi, '')"
            >
          </div>
          <v-btn fab depressed small color="indigo" dark @click.prevent="OpenLinkDetail()">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
        <div v-if="dialogBiolink.data" class="px-0 px-sm-10 py-4">
          <div class="ebrightskin-biolink-area">
            <div class="ebrightskin-biolink-container bg-cover" :style="{ backgroundImage: 'url(' + (dialogBiolink.data.background || '/images/bg-biolink.png') + ')' }" >
              <span class="ebrightskin-biolink-change-background-clear" @click.prevent="ResetBackground()">
                <v-icon small color="red">
                  mdi-orbit-variant
                </v-icon>
              </span>
              <span class="ebrightskin-biolink-change-background" @click.prevent="UploadImage('background', -1)">
                <v-icon small color="orange">
                  {{ dialogBiolink.data.background ? 'mdi-camera-party-mode' : 'mdi-camera' }}
                </v-icon>
              </span>
              <div class="ebrightskin-biolink-logo">
                <div class="bg-cover c-pointer" :style="{ backgroundImage: 'url(' + (dialogBiolink.data.logo || '/images/no-image.jpg') + ')' }" @click.prevent="UploadImage('logo', -1)" />
              </div>
              <textarea
                v-model="dialogBiolink.data.title"
                type="text"
                rows="1"
                class="ebrightskin-biolink-title"
              />
              <textarea
                v-model="dialogBiolink.data.subtitle"
                rows="1"
                class="ebrightskin-biolink-subtitle"
              />
              <div class="ebrightskin-biolink-banner">
                <span v-if="dialogBiolink.data.banner && !videoPlay" class="btn-icon ebrightskin-biolink-banner-btn-image-clear" @click.prevent="CLEAR_BANNER('banner')">
                  <v-tooltip top color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon x-small color="red" v-bind="attrs" v-on="on">
                        mdi-image-remove
                      </v-icon>
                    </template>
                    <span class="caption-small">Remove Banner Image</span>
                  </v-tooltip>
                </span>
                <span v-if="!videoPlay" class="btn-icon ebrightskin-biolink-banner-btn-image" @click.prevent="UploadImage('banner', -1)">
                  <v-tooltip top :color="dialogBiolink.data.banner ? 'orange' : 'grey'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon x-small :color="dialogBiolink.data.banner ? 'orange' : 'grey'" v-bind="attrs" v-on="on">
                        mdi-image
                      </v-icon>
                    </template>
                    <span class="caption-small">{{ dialogBiolink.data.banner ? 'Change image' : 'Upload image' }}</span>
                  </v-tooltip>
                </span>
                <span v-if="dialogBiolink.data.video && !videoPlay" class="btn-icon ebrightskin-biolink-banner-btn-video-clear" @click.prevent="CLEAR_BANNER('video')">
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon x-small color="red" v-bind="attrs" v-on="on">
                        mdi-video-off-outline
                      </v-icon>
                    </template>
                    <span class="caption-small">Remove Video</span>
                  </v-tooltip>
                </span>
                <span v-if="!videoPlay" class="btn-icon ebrightskin-biolink-banner-btn-video" @click.prevent="UPLOAD_VIDEO()">
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon x-small color="blue" v-bind="attrs" v-on="on">
                        mdi-video
                      </v-icon>
                    </template>
                    <span class="caption-small">{{ dialogBiolink.data.video ? 'Change video' : 'Upload video' }}</span>
                  </v-tooltip>
                </span>
                <div v-if="dialogBiolink.data.video && videoPlay">
                  <vue-core-video-player autoplay muted @play="''" :src="dialogBiolink.data.video" />
                </div>
                <div v-else class="bg-cover" :style="{ backgroundImage: 'url(' + (dialogBiolink.data.banner || '/images/no-image.jpg') + ')' }" />
                <div
                  v-if="dialogBiolink.data.video && !videoPlay"
                  class="d-flex align-center justify-center"
                  style="position: absolute;top:0px;left:0px;width:100%;height:100%;background:rgba(0, 0, 0, .03);border-radius:10px;"
                >
                  <v-icon large color="primary" @click.prevent="videoPlay = true">
                    mdi-play-circle
                  </v-icon>
                </div>
              </div>
              <textarea
                v-model="dialogBiolink.data.headline"
                rows="1"
                class="ebrightskin-biolink-headline"
              />
              <textarea
                v-model="dialogBiolink.data.headlinesub"
                rows="1"
                class="ebrightskin-biolink-headlinesub"
              />
              <div class="ebrightskin-biolink-links">
                <draggable v-model="dialogBiolink.data.links" group="link" @start="drag = true" @end="drag = false">
                  <a v-for="(l, iL) in dialogBiolink.data.links" :key="'link-' + iL" :href="(dialogBiolink.data.links[iL].link || '#')" class="ebrightskin-biolink-link" @click.prevent>
                    <div class="ebrightskin-biolink-link-icon">
                      <div class="bg-cover" :style="{ backgroundImage: 'url(' + (dialogBiolink.data.links[iL].icon || '/images/no-image.jpg') + ')' }" @click.prevent="UploadImage(null, iL)" />
                    </div>
                    <input
                      v-model="dialogBiolink.data.links[iL].title"
                      type="text"
                      class="ebrightskin-biolink-link-title"
                    />
                    <div class="option-biolink-link">
                      <span class="option-biolink-link-link">
                        <span>
                          <v-icon small @click.prevent="dialogBiolink.data.links[iL].openLink = !l.openLink">
                            {{ l.openLink ? 'mdi-link-off' : 'mdi-link' }}
                          </v-icon>
                        </span>
                      </span>
                      <span class="option-biolink-link-show">
                        <span class="switch">
                          <input :checked="l.hide ? false : 'checked'" type="checkbox">
                          <span class="slider round" @click.prevent="dialogBiolink.data.links[iL].hide = !l.hide" />
                        </span>
                      </span>
                    </div>
                    <input
                      v-if="l.openLink"
                      v-model="dialogBiolink.data.links[iL].link"
                      type="text"
                      class="ebrightskin-biolink-link-url"
                    />
                    <span class="option-biolink-actions-counter align-center justify-center">
                      <span v-if="parseInt(dialogBiolink.data.id)" class="orange darken-2 white--text asd">
                        {{ parseInt(l.counter) || 0 }}
                      </span>
                    </span>
                    <span class="option-biolink-actions">
                      <span v-if="dialogBiolink.data.links.length !== 1" class="red" @click.prevent="dialogBiolink.data.links.splice(iL, 1)">
                        <v-icon x-small color="white">
                          mdi-close
                        </v-icon>
                      </span>
                      <span class="green">
                        <v-icon x-small color="white" @click.prevent="dialogBiolink.data.links.push(Object.assign(Object.assign({}, l), { openLink: false, hide: false }))">
                          mdi-content-copy
                        </v-icon>
                      </span>
                    </span>
                  </a>
                </draggable>
              </div>
              <div class="ebrightskin-biolink-footer">
                Copyright &copy; 2022 eBright Skin<br>All Right Reserved
              </div>
            </div>
          </div>
        </div>
        <div class="text-center px-4 pt-4 pb-10">
          <v-btn depressed class="rounded-pill px-4 text-capitalize font-weight-bold caption" dark :color="parseInt(dialogBiolink.data.id) ? 'orange' : 'indigo'" @click.prevent="BIOLINK_PROCESS()">
            {{ parseInt(dialogBiolink.data.id) ? 'Update Biolink' : 'Create New Biolink' }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loadingUpload"
      hide-overlay
      persistent
      light
      width="300"
    >
      <v-card light>
        <v-card-title
          class="caption justify-center py-3 subtitle-1"
        >
          Uploading
        </v-card-title>
        <v-divider />
        <v-card-text class="d-flex flex-column pt-2 align-center justify-center">
          <div>
            <strong>{{ $store.state.progress }}%</strong> Uploading, please wait ...
          </div>
          <v-progress-linear
            :value="$store.state.progress"
            color="blue"
            class="mt-2 mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="DialogHomeSetting.show"
      scrollable
      max-width="420px"
    >
      <v-card light>
        <v-card-title class="body-2 font-weight-bold">
          <v-icon class="mr-2" small>
            mdi-home-edit
          </v-icon>
          Biolink - Home Setting
        </v-card-title>
        <v-divider />
        <v-card-text v-if="DialogHomeSetting.data" class="py-7">
          <v-text-field
            v-model="DialogHomeSetting.data.title"
            label="Title"
            outlined
            block
            dense
            class="mb-4"
            autocomplete="off"
            hide-details
          />
          <v-text-field
            v-model="DialogHomeSetting.data.subtitle"
            label="Subtitle"
            outlined
            block
            dense
            class="mb-4"
            autocomplete="off"
            hide-details
          />
          <v-text-field
            v-model="DialogHomeSetting.data.whatsapp"
            label="Whatsapp Number"
            outlined
            block
            dense
            class="mb-4"
            autocomplete="off"
            hide-details
            @keypress="$NumberOnly"
          />
          <v-textarea
            v-model="DialogHomeSetting.data.whatsapp_message"
            label="Whatsapp Message"
            outlined
            block
            dense
            autocomplete="off"
            hide-details
            rows="2"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red"
            text
            small
            dark
            class="text-capitalize"
            @click="DialogHomeSetting.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            depressed
            color="purple"
            dark
            small
            class="rounded-pill px-8 caption font-weight-bold text-capitalize"
            @click="HomeSettingProcess()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
const Biolink = {
  id: null,
  slug: 'LinkSlug',
  logo: '',
  background: '',
  title: 'Title Here',
  subtitle: 'Write Subtitle Here',
  banner: '',
  headline: 'Your headline here',
  headlinesub: 'Your sub-headline here',
  links: [
    { icon: '', title: 'Link Title Here', link: '#', openLink: false, hide: false }
  ],
  status: 1
}
let searchTimeout = null
export default {
  components: {
    draggable
  },
  data: () => ({
    loadingUpload: false,
    videoPlay: false,
    search: '',
    tableLoading: true,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 10,
    options: {},
    headers: [
      { text: '#ID', value: 'id' },
      { text: 'Link', value: 'slug' },
      { text: 'Title', value: 'title' },
      { text: 'Headline', value: 'headline' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    dialogBiolink: {
      show: false,
      data: Object.assign({}, Biolink)
    },
    DialogHomeSetting: {
      show: false,
      data: null
    }
  }),
  watch: {
    search () {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.LoadBiolink()
      }, 300)
    },
    options: {
      handler () {
        this.LoadBiolink()
      },
      deep: true
    }
  },
  mounted () {
    this.LoadBiolink()
    this.HomeSettingLoad()
  },
  methods: {
    OpenHomeSetting () {
      this.DialogHomeSetting.show = true
    },
    HomeSettingLoad () {
      this.$store.dispatch('BIOLINK_SETTING_GET')
        .then((res) => {
          if (res.status) {
            this.DialogHomeSetting.data = Object.assign({}, res.data.data)
          }
        })
    },
    HomeSettingProcess () {
      this.$store.dispatch('BIOLINK_SETTING_PROCESS', this.DialogHomeSetting.data)
        .then((res) => {
          if (res.status) {
            this.DialogHomeSetting.show = false
            this.$store.commit('SNACKBAR', { show: true, text: 'Updated!' })
            this.HomeSettingLoad()
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong!' })
          }
        })
    },
    copyText (txt) {
      // const el = document.getElementById('shorturl')
      if (this.$copyText(txt || ('https://ebright.skin/' + this.dialogBiolink.data.slug))) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Url copied!' })
      }
    },
    CLEAR_BANNER (v) {
      if (!confirm('Clear ' + v + ' ?')) {
        return false
      }
      if (v === 'video') {
        this.dialogBiolink.data.video = ''
      } else if (v === 'banner') {
        this.dialogBiolink.data.banner = ''
      }
    },
    ResetBackground () {
      if (!confirm('Clear background ?')) {
        return false
      }
      this.dialogBiolink.data.background = ''
    },
    OpenLinkDetail (link, duplicate) {
      this.dialogBiolink.data = null
      this.videoPlay = false
      if (link) {
        if (link.links) {
          link.links = typeof link.links === 'string' ? this.$StrToArray(link.links) : (link.links || [])
          if (!link.links.length) {
            link.links = [{ icon: '', title: 'Link Title Here', link: '#', openLink: false, hide: false }]
          }
        } else {
          link.links = [{ icon: '', title: 'Link Title Here', link: '#', openLink: false, hide: false }]
        }
        const linkBinding = Object.assign({}, link)
        if (duplicate) {
          linkBinding.id = null
          linkBinding.slug += '2'
        }
        linkBinding.links = linkBinding.links.map(r => {
          if (r.hide) {
            r.hide = true
          } else {
            r.hide = false
          }
          return r
        })
        setTimeout(() => {
          this.dialogBiolink.data = linkBinding
        }, 300)
      } else {
        setTimeout(() => {
          this.dialogBiolink.data = Object.assign({}, Biolink)
        }, 300)
      }
    },
    LoadBiolink () {
      this.tableLoading = true
      this.table = []
      this.tableTotal = 0
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = '?q=' + (this.search || '') + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('BIOLINK_GET', params)
        .then((res) => {
          if (res.status) {
            this.table = res.data.data.data.map((r) => {
              /* eslint-disable-next-line */
              r.status = parseInt(r.status) ? true : false
              return r
            }) || []
            this.tableTotal = parseInt(res.data.data.total) || 0
          }
          this.tableLoading = false
        })
    },
    UpdateStatus (id, slug, status) {
      const newStatus = status ? 1 : 0
      this.$store.dispatch('BIOLINK_PROCESS', {
        id: id,
        slug: slug,
        status: newStatus
      })
    },
    BIOLINK_PROCESS () {
      const param = Object.assign({}, this.dialogBiolink.data)
      param.links = this.$ArrayToStr(param.links)
      delete param.visit_counter
      this.$store.dispatch('BIOLINK_PROCESS', param)
        .then((res) => {
          if (res.status) {
            this.$store.commit('SNACKBAR', { show: true, text: 'Biolink ' + (param.id ? 'Updated' : 'Created') })
            this.OpenLinkDetail()
            this.LoadBiolink()
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: (res.data.response.data.message || 'Something went wrong') })
          }
        })
    },
    BIOLINK_DELETE (id) {
      if (!confirm('Delete biolink ?')) {
        return false
      }
      this.$store.dispatch('BIOLINK_DELETE', id)
        .then((res) => {
          this.OpenLinkDetail()
          this.LoadBiolink()
        })
    },
    UploadImage (key, linkIndex) {
      const f = document.getElementById('imageUploader')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'imageUploader')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', '.jpg,.jpeg,.png')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, key, linkIndex)
      })
      document.body.appendChild(el)
      el.click()
    },
    UPLOAD_VIDEO () {
      this.videoPlay = false
      const f = document.getElementById('videoUploader')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'videoUploader')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', '.mp4')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, 'video')
      })
      document.body.appendChild(el)
      el.click()
    },
    async UPLOAD_HANDLER (event, key, linkIndex) {
      /* eslint-disable */
      const $ = this
      $.loadingUpload = true
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          let newSizeImage = null
          if (key ==='video') {
            newSizeImage = res.data
          } else if (key ==='background') {
            newSizeImage = await $.$resizeImage(res.data, 1400, 1400)
          } else if (key ==='banner') {
            newSizeImage = await $.$resizeImage(res.data, 500, 500)
          } else {
            newSizeImage = await $.$resizeImage(res.data, 350, 350)
          }
          await $.$store.dispatch('UPLOAD_FILE', {
            file: newSizeImage,
            source: 'biolink',
            size: key === 'background' ? 1400 : (key === 'banner' ? 500 : 350)
          }).then((resUpload) => {
            if (key === 'video') {
              $.dialogBiolink.data[key] = resUpload.data.data.secure_url
            } else if (key && key !== 'links') {
              $.dialogBiolink.data[key] = resUpload.data.data.secure_url
            } else if (linkIndex > -1) {
              $.dialogBiolink.data.links[linkIndex].icon = resUpload.data.data.secure_url
            } else {
              $.$store.commit('SNACKBAR', { show: true, text: 'Ops, invalid keys!' })
            }
            $.loadingUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.commit('SNACKBAR', { show: true, text: 'Ops, file image is too large. Max size (images < 5Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
          $.loadingUpload = false
        }
      })
    },
  }
}
</script>

<style lang="scss">
a,
a:link,
a:visited,
a:hover,
a:active{
  text-decoration: none;
}
.editable-biolink {
  input, textarea {
    outline: none;
    border: none;
    background-color: transparent;
    text-decoration: none;
  }
}
.bg-cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ebrightskin-biolink-area {
  position: relative;
  width: 100%;
  .ebrightskin-biolink-container {
    position: relative;
    margin: 0 auto;
    background-color: rgba(167, 101, 1, 0.1);
    max-width: 500px;
    border-radius: 10px;
    padding: 50px 30px;
    text-align: center;
    .ebrightskin-biolink-change-background-clear,
    .ebrightskin-biolink-change-background {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 35px;
      height: 25px;
      background: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, .05);
    }
    .ebrightskin-biolink-change-background-clear {
      top: 10px;
      right: 55px;
    }
    input, textarea {
      text-align: center;
    }
    .ebrightskin-biolink-logo {
      text-align: center;
      >.bg-cover {
        display: inline-block;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        background-color: #fff;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, .01);
      }
    }
    .ebrightskin-biolink-title {
      margin-top: 10px;
      font-weight: bold;
      font-size: 18px;
      color: #555;
      display: block;
      width: 100%;
      min-height: 24px;
    }
    .ebrightskin-biolink-subtitle {
      font-size: 16px;
      color: #777;
      display: block;
      width: 100%;
      min-height: 21px;
    }
    .ebrightskin-biolink-banner {
      position: relative;
      margin: 30px 0px;
      >.btn-icon {
        position: absolute;
        right: 10px;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 4px;
        width: 27px;
        height: 20px;
        padding: 0px;
        line-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index:1;
        box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, .05);
        &:hover {
          background: rgba(255, 255, 255, 1);
        }
        &.ebrightskin-biolink-banner-btn-image {
          top: 10px;
        }
        &.ebrightskin-biolink-banner-btn-image-clear {
          top: 10px;
          right: 40px;
        }
        &.ebrightskin-biolink-banner-btn-video {
          top: 40px;
        }
        &.ebrightskin-biolink-banner-btn-video-clear {
          top: 40px;
          right: 40px;
        }
      }
      >.bg-cover {
        display: block;
        width: 100%;
        padding-bottom: 50%;
        background-color: #fff;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
        box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, .01);
      }
    }
    .ebrightskin-biolink-headline {
      font-size: 24px;
      font-weight: bold;
      margin-top: 10px;
      min-height: 27px;
      display: block;
      width: 100%;
    }
    .ebrightskin-biolink-headlinesub {
      font-size: 18px;
      min-height: 27px;
      padding-bottom: 10px;
      display: block;
      width: 100%;
    }
    .ebrightskin-biolink-links {
      width: 100%;
      display: flex;
      align-content: center;
      flex-direction: column;
      padding: 20px 0px;
      .ebrightskin-biolink-link {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px 5px;
        border-radius: 50px;
        line-height: 100%;
        margin: 7px 0px;
        box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, .005);
        background: rgba(255, 255, 255, .7);
        transition: all .3s ease-in-out;
        &:hover {
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 5px 7px 5px rgba(0, 0, 0, .01);
        }
        >.ebrightskin-biolink-link-icon {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          >.bg-cover {
            display: inline-block;
            width: 90%;
            height: 90%;
            border-radius: 50%;
            background-color: #f0f0f0;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        >.ebrightskin-biolink-link-title {
          width: calc(100% - 50px);
          text-align: left;
          padding: 0px 40px 0px 10px;
          font-size: 18px;
          line-height: 140%;
          min-height: 16.8px;
          text-align: center;
          color: #915604;
          text-decoration: none !important;
        }
        .option-biolink-link {
          position: relative;
          display: flex;
          align-items: center;
          .option-biolink-link-link {
            position: absolute;
            left: -25px;
            top: -1px;
          }
          .switch {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 17px;
          }

          .switch input { 
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .3s;
            transition: .3s;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 13px;
            width: 13px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: .3s;
            transition: .3s;
          }

          input:checked + .slider {
            background-color: #2196F3;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(13px);
            -ms-transform: translateX(13px);
            transform: translateX(13px);
          }

          /* Rounded sliders */
          .slider.round {
            border-radius: 17px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        }
        >.ebrightskin-biolink-link-url {
          position: absolute;
          right: 5px;
          bottom: -10px;
          background: #000;
          color: #fff;
          text-align: left;
          border-radius: 20px;
          padding: 3px 7px;
          font-size: 11px;
          font-weight: bold;
          width: calc(100% - 55px);
        }
        .option-biolink-actions {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          right: -25px;
          top: 0px;
          height: 100%;
          >span {
            display: flex;
            width: 20px;
            height: 20px;
            display: block;
            border-radius: 50%;
            margin: 2px 0px;
            cursor: pointer;
            opacity: .8;
            transition: all .3s ease-in-out;
            &:hover {
              opacity: 1;
            }
          }
        }
        .option-biolink-actions-counter {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: center;
          left: -25px;
          top: 0px;
          height: 100%;
          >span {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 4px 0px;
            font-size: .5rem;
            cursor: pointer;
            opacity: .8;
            transition: all .3s ease-in-out;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .ebrightskin-biolink-footer {
      font-size: 11px;
      color: #555;
      padding: 40px 20px 20px 20px;
    }
  }
}
</style>
